import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from './auth.service';
import { Discipline } from '../discipline';
import { Skill } from '../skill';
import { Experience } from '../experience';
import { Workplace } from '../workplace';
import { Project } from '../project';
import { Image } from '../image';
import { Tag } from '../tag';

@Injectable()
export class AdminService {
    public httpOptions;
    public log = {};
    backend_root:string;
    constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) storage: StorageService, _authService: AuthService) {

      this.backend_root = _authService.backend_root;
      _authService.get_login.subscribe(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
            'Authorization': 'JWT ' + _authService.token
            })
        };
      });

    }
    //############## DISCIPLINES #######################
    public getDisciplines():Observable<Discipline[]>{
        return this.http.get<Discipline[]>(this.backend_root + '/disciplines/', this.httpOptions as Object);
    }
    public createDiscipline(disciplineForm):Observable<Discipline>{
      return this.http.post<Discipline>(this.backend_root + '/disciplines/', disciplineForm, this.httpOptions as Object);
    }
    public updateDiscipline(disciplineForm,id):Observable<Discipline>{
      return this.http.patch<Discipline>(this.backend_root + '/disciplines/'+id+'/', disciplineForm, this.httpOptions as Object);
    }
    public deleteDiscipline(discipline_id):Observable<any>{
      return this.http.delete(this.backend_root + '/disciplines/'+ discipline_id, this.httpOptions as Object);
    }

    //############## SKILLS #######################

    public createSkill(skill):Observable<Skill>{
      return this.http.post<Skill>(this.backend_root + '/skills/', skill, this.httpOptions as Object);
    }
    public updateSkill(skill,id):Observable<Skill>{
      return this.http.patch<Skill>(this.backend_root + '/skills/', skill, this.httpOptions as Object);
    }
    public deleteSkill(skill_id):Observable<any>{
      return this.http.delete(this.backend_root + '/skills/'+skill_id+"/", this.httpOptions as Object);
    }

    //############## EXPERIENCE #######################

    public getExperiences():Observable<Experience[]>{
      return this.http.get<Experience[]>(this.backend_root + '/experiences/', this.httpOptions as Object);
    }
    public createExperience(experience):Observable<Experience>{
      return this.http.post<Experience>(this.backend_root + '/experiences/', experience, this.httpOptions as Object);
    }
    public updateExperience(experience,id):Observable<Experience>{
      return this.http.patch<Experience>(this.backend_root + '/experiences/'+id+'/', experience, this.httpOptions as Object);
    }
    public deleteExperience(experience_id):Observable<any>{
      return this.http.delete(this.backend_root + '/experiences/'+ experience_id + "/", this.httpOptions as Object);
    }

    //############## WORKPLACE #######################

    public getWorkplaces():Observable<Workplace[]>{
      return this.http.get<Workplace[]>(this.backend_root + '/workplaces/', this.httpOptions as Object);
    }
    public createWorkplace(workplace):Observable<Workplace>{
      return this.http.post<Workplace>(this.backend_root + '/workplaces/', workplace, this.httpOptions as Object);
    }
    public updateWorkplace(workplace,id):Observable<Workplace>{
      if(workplace.date_end instanceof Date) workplace.date_end=this.yyyymmdd(workplace.date_end);
      return this.http.patch<Workplace>(this.backend_root + '/workplaces/'+id+'/', workplace, this.httpOptions as Object);
    }
    public deleteWorkplace(workplace_id):Observable<any>{
      return this.http.delete(this.backend_root + '/workplaces/'+ workplace_id + "/", this.httpOptions as Object);
    }

    //############## tags #######################

    public getTags():Observable<any>{
      return this.http.get<any[]>(this.backend_root + '/tags/', this.httpOptions as Object);
    }

    // ############# projects ##################

    public getProjects():Observable<Project[]>{
      return this.http.get<Project[]>(this.backend_root + '/projects/', this.httpOptions as Object);
    }
    public createProject(project):Observable<Project>{
      return this.http.post<Project>(this.backend_root + '/projects/', project, this.httpOptions as Object);
    }
    public updateProject(project,id):Observable<Project>{
      return this.http.patch<Project>(this.backend_root + '/projects/'+id+'/', project, this.httpOptions as Object);
    }
    public deleteProject(project_id):Observable<any>{
      return this.http.delete(this.backend_root + '/projects/'+ project_id + "/", this.httpOptions as Object);
    }

    // ############# image ####################

    public createImage(image):Observable<Image>{
      return this.http.post<Image>(this.backend_root + '/images/', image, this.httpOptions as Object);
    }
    public updateImage(image,id):Observable<Image>{
      return this.http.patch<Image>(this.backend_root + '/images/'+id+'/', image, this.httpOptions as Object);
    }
    public deleteImage(image_id):Observable<any>{
      return this.http.delete(this.backend_root + '/images/'+ image_id + "/", this.httpOptions as Object);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
    private yyyymmdd(date):String{
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      return [date.getFullYear(),(mm>9 ? '' : '0') + mm,(dd>9 ? '' : '0') + dd].join('');
    }
}
