<div class="row filter" [hidden]="!(exp_filters.length > 0)">
    <div class="col-md-12">
      Applied filters: <a *ngFor="let tag of exp_filters" (click)="removeFilter(tag)" class="btn btn-dark"> {{tag}} <i class="fa fa-minus-circle" aria-hidden="true"></i></a>
    </div>
  </div>

  <div id="greetings"></div>
  <h1 class="main-title" >Hi, I’m Sven</h1>
  <h3 class="sec-title">I’m a human being, father, life partner, family member, and other private personal stuff.</h3>
  <br>
  <p style="text-align:justify;font-size:10pt">
    Even though my main study is mechanical Engineering and a good part of my experience is related with energy and physics phenomena modelling, I also have enjoyed a good amount of developing time in many languages and formats, as front-end, back-end, full-stack developer. My first experience with developing was with Flash 5, when I was in 8th grade, I loved doing animations and from that point I learned how to develop games and then later in university all the magic behind stand-alone software, web-platforms, etc.
<br><br> Another of my areas of interest is in the strategical analysis and coaching. I worked for six years as a micro-entrepreneur’s trainer while studying and after that I have devoted several hours to workshops design, setup and run to overcome different type of challenges, regarding for example the social crisis of 18th October and its effect on my work division.
<br><br> I love teamwork, I’m a very sociable person and a reliable partner, my mates and every boss that I’ve had have told me that I’m a very efficient and proactive professional, and I try to keep those expectations alive. I also have worked as volunteer in sociocracy workshops for my district political organization (training facilitators and designing material). 
<br><br> I would say that my main ability is to learn fast, and to understand problems and find solutions in the most sustainable way. I consider myself an out of the box thinker and I enjoy creating structures that hold systems together. Also, as I worked for almost four years in a consulting company, I learned to be very efficient with time scheduling and project resources management.
  </p>
  <!--<h3 class="sec-title">These are some of my last documented projects, you might want to look</h3>
  <br>
  <div class="row">
    <div *ngFor="let proj of projects | slice:0:6; let i=index" class="col-sm-4 stretched">
      <div class="project-card" [routerLink]="['/portfolio',proj.shortname]">
        <div class="project-card-title">
          <div class="orange-title-block"></div>
          {{proj.name}}
        </div>
        <div class="project-card-subtitle">
          {{proj.subtitle}}
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <a class="btn btn-outline-info btn-centered" [routerLink]="['/portfolio']">>Show more</a>
  </div>
  <br><br>
  -->
  <h2 class="centered">But also, you might want to know about</h2>
  <div class="row">
    <div class="col-sm-4 experienced-banner main-banner">
      How much <br>
      <b>Experience</b><br>
      I have
    </div>
    <div class="col-sm-4 comfortable-banner main-banner">
      How <br>
      <b>Comfortable</b><br>
      I feel
    </div>
    <div class="col-sm-4 joyfull-banner main-banner">
      How much <br>
      <b>Enjoyment</b><br>
      I get
    </div>
  </div>
  <br>
  <h2 class="centered">doing different sort of things related to</h2>
  <div class="centered">
    <a href="#disciplines">
      <!--<fa-icon [icon]="['fas','angle-down']" size="5x"></fa-icon>-->
      <i class="fa fa-angle-down fa-5x"></i>
      <!--<fa-icon [icon]="faAngleDown" size="5x"></fa-icon>-->
    </a>
  </div>
  
  <br><br><br><br>

  <h1 class="centered" id="disciplines">Skills</h1>
  <p class="centered">
  <span class="score score-exp" *ngFor="let i of [1,2,3,4,5,6]"></span>
  <span class="score score-com" *ngFor="let i of [1,2,3,4,5,6]"></span>
  <span class="score score-joy" *ngFor="let i of [1,2,3,4,5,6]"></span>
  </p>
  <p class="centered">
    Blue squares <span class="score score-exp"></span> (from 1 to 10) show how much experience I have with that skill <br>
    Greenish squares <span class="score score-com"></span> (from 1 to 10) show how comfortable I feel using that skill <br>
    Orange squares <span class="score score-joy"></span> (from 1 to 10)  show how much I enjoy using that skill
  </p>
  <br><br><br>

  <div> 
    <div class="row" *ngFor="let disc of disciplines | orderBy:'order'">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="discipline-image-wrap" id="{{disc.shortname}}">
              <img src="{{disc.image}}" [hidden]="!toggleImages" alt="">
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <div class="discipline-title-wrapper"><div class="grey-title-block"></div><h3 class="discipline-title">{{disc.name}}</h3></div>
          </div>
        </div>
        <br>
        <div class="row skill-row" *ngFor="let skill of disc.skills | orderBy:'-order'">
          <div class="col-md-6 skill-name">{{skill.name}}</div>
          <div class="col-md-6">
            <table width="100%">
              <tr>
                <td width="33%">
                  <span class="score score-exp" *ngFor="let i of [].constructor(skill.exp)"></span>
                </td>  
                <td width="33%">
                  <span class="score score-com" *ngFor="let i of [].constructor(skill.com)"></span>
                </td>  
                <td width="33%">  
                  <span class="score score-joy" *ngFor="let i of [].constructor(skill.enj)"></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br><br><br>
  <div class="toggle-imgs-menu">
    Toggle imgs <a class="btn btn-{{toggleImages?'success':'danger'}}" (click)="toggleImages = toggleImages?false:true">{{toggleImages?"on":"off"}}</a>
  </div>
  
  <h1 class="centered" id="experience">Experience</h1>
  <h5 class="centered">These are some of my experiences, just a fair selection</h5>
  <br><br><br>

  <div class="col-md-12">
    <div *ngFor="let work of workplaces" id="{{work.shortname}}">
      <div class="row">
        <div class="col-md-3 exp-dates-wrapper">
            <div class="exp-dates">
                {{work.date_ini | date:'MMM y'}} <br> <small>to</small> {{work.date_end | date:'MMM y'}}  
            </div>
        </div>
        <div class="col-md-9 exp-title-wrapper">
          <div>
            <div class="white-title-block"></div>
            <h4 class="exp-title">{{work.name}}</h4>
            <br><br>
            <p>{{work.description}}</p>
          </div>  
        </div>
      </div>
      <br>
      <div class="project-wrapper sample-show-hide" *ngFor="let exp of work.experiences | expfilter:exp_filters" >
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <h5 class="project-title"><i class="fa fa-caret-right"></i> {{exp.name}}</h5>
          <p>Client: <a href="{{exp.client_url}}">{{exp.client}}</a></p>

          <p *ngIf="exp.urls.length>0">Links: <a *ngFor="let url of exp.urls;let isLast=last;" href="{{url.link}}">{{url.text}}{{isLast? '' : ', '}}</a> </p>
          </div>


          <div class="col-md-3"></div>
          <div class="col-md-9">
            <p>Role: {{exp.role}}</p>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <a *ngFor="let tag of exp.tags" (click)="addFilter(tag)" class="btn btn-dark tag">
              {{tag}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br><br>

  <h1 class="centered" id="others">Other intel</h1>
  <h5 class="centered">Some other useful data in case you are interested</h5>
  <br>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <ul>
          <li>
            I was vice president of mountaneering club RAMUCH (Rama de Montañismo de la Universidad de Chile).
            <br>
            I developed the club's intranet that serves as financial platform, members payments and management system, equipment managing and loning and expeditions planification.
            <br>
            And I did it for free, because I really enjoy club activities.
          </li>
          <br>
          <li>I was an active scout member for at least 8 years of my life. Once scout, allways scout. Some might say that mountaneering is just the second part.</li>
          <br>
          <li>I'm currently writting a science fiction novel called "La ciudadela de Kanthar" (Kanthar's Citadel).</li>
          <br>
          <li>I also was treasurer of my students union in the mechanical engineering department.</li>
      </ul>
    </div>
  </div>

  <div class="footer">
    <br>
    <div class="row">
      <div class="col-md-12 centered">
        <i class="fa fa-envelope"></i> sven.harfagar@gmail.com
      </div>
    </div>
    <br>
  </div>