import { Component, OnInit, ElementRef, AfterViewInit,AfterContentInit, Inject, Injectable, Renderer2, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Project} from "../project";
import {map, startWith} from 'rxjs/operators';
import {MainService} from '../services/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})

export class PortfolioComponent implements OnInit {
  private short:string;
  projects:Project[];
  Project:Project={name:""};
  private _album = [];

  constructor(private route: ActivatedRoute,private _mainService:MainService,@Inject(DOCUMENT) private document: Document, private renderer:Renderer2,private sanitizer:DomSanitizer,private _lightbox: Lightbox, private elementRef:ElementRef) { 

  }

  @HostListener('click', ['$event'])
  onClick(btn): void {
    if(btn.path[1].classList[0]=="project_img_holder"){
      var obj = btn.path[1]; 
      const index = obj.classList[1].substring(6, obj.classList[1].length);
      //console.log(index);
      this.open(index);
    }
  }

  ngAfterViewInit() {
    // assume dynamic HTML was added before
    //this.elementRef.nativeElement.querySelector('img').addEventListener('click', this.onClick.bind(this));
  }
  ngOnInit(): void {
    this._mainService.get_data.subscribe(data=>{
      this.projects=data.projects;
      this.route.params.subscribe(params => {
        //console.log(params);
        if(params['short']){
          this.short = params['short']; // (+) converts string 'id' to a number
          if(this.projects){
            this.Project = this.projects.find(e=>e.shortname===this.short);
          }
        }else{
          this.short = "thisweb";
          if(this.projects){
            this.Project = this.projects.find(e=>e.shortname===this.short);
          }
        }
        if(this.Project.images){
          this.Project.images.forEach(image_i=>{
            const src = image_i.image;
            const caption = image_i.name;
            const thumb = image_i.image.substring(0,image_i.image.indexOf(".jpg")) + '-thumb.jpg';
            const image = {
              src: src,
              caption: caption,
              thumb: thumb
            };
            this._album.push(image);
          });
          //console.log(this._album);
          //this.elementRef.nativeElement.querySelector('.project_img_holder').addEventListener('click', this.open.bind(this));
        }
      });
    });
  }
  open(index:number): void{
    this._lightbox.open(this._album, index);
  }

}
