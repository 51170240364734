import { Component, OnInit } from '@angular/core';
import {Project} from "../project";
import {Image} from "../image";
import {AuthService} from '../services/auth.service';
import {AdminService} from '../services/admin.service';
//import {DISCIPLINES} from '../mock-projects';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

export class ProjectsComponent implements OnInit {
  public Project: Project = {name:"",shortname:""};
  public Projects: Project[] = [];
  public Image = {id:0,image:"",name:"",order:0,project:{}};
  public Images: Image[];
  public create_update_proj = "create";
  public create_update_img = "create";
  public project_image_url;
  public project_img;
  public imagePath;
  
  constructor(private _adminService:AdminService,_authService:AuthService) { 
    _authService.get_login.subscribe(data => {
      if(data==true){
        this.getAll();
      }      
    });
  }
  



  ngOnInit(): void {
    
  }

  public getAll():void{
    this._adminService.getProjects().subscribe(projects => this.Projects = projects);
  }

  setNewProject(){
    this.Project = {name:"",shortname:""};
    this.project_image_url = "";
    this.create_update_proj = "create";
  }
  previewFile(files){
    if (files.length === 0)
      return;
  
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.project_img = files[0];
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => {
      this.project_image_url = reader.result; 
    }
  }

  create_update_project(){
    if(this.create_update_proj == "create"){
      this.create_project();
    }
    if(this.create_update_proj == "update"){
      this.update_project();
    }
  }
  
  create_project(){
    this._adminService.createProject(this.Project).subscribe( res =>{
      this.Projects.push(res);
    });
  }
  update_project(){
    this._adminService.updateProject(this.Project,this.Project.id).subscribe( res =>{
      let auxproj = this.Projects.find(proj => proj.id == res.id);
      this.Projects[this.Projects.indexOf(auxproj)] = res;
      this.setNewProject;
    });
  }

  delete_project(){
    this._adminService.deleteProject(this.Project.id).subscribe( res =>{
        let auxproj = this.Projects.find(proj => proj.id == this.Project.id);
        this.Projects.splice(this.Projects.indexOf(auxproj),1);
    });
  }
  
  set_preupdate_project(proj){
    this.Project = Object.assign({},proj);
    this.create_update_proj = "update";
  }

  set_deletable_project(proj){
    this.Project = Object.assign({},proj);
  }

  select_project(proj){
    this.Project = proj;
  }

  create_update_image(){
    if(this.create_update_img == "create"){
      this.create_image();
    }
    if(this.create_update_img == "update"){
      this.update_image();
    }
  }

  setNewImage(){
    this.Image = {id:0,image:"",name:"",order:0,project:{}};
    this.create_update_img = "create";
  }

  create_image(){
    const newImageForm = new FormData();
    if(this.project_img != "" && this.project_img != null){
      newImageForm.append('image', this.project_img, this.project_img.name);
    }
    //newImageForm.append('id',this.Project.id.toString());
    if(this.Image.name && this.Image.order && this.Project.id){
      newImageForm.append('name',this.Image.name);
      newImageForm.append('order',this.Image.order.toString());
      newImageForm.append('project',this.Project.id.toString());
    }
    this.Image.project = this.Project.id;
    this._adminService.createImage(newImageForm).subscribe( res =>{
      this.Project.images.push(res);
    });
  }
  update_image(){
    const newImageForm = new FormData();
    if(this.project_img != "" && this.project_img != null){
      newImageForm.append('image', this.project_img, this.project_img.name);
    }
    //newImageForm.append('id',this.Project.id.toString());
    newImageForm.append('name',this.Image.name);
    newImageForm.append('order',this.Image.order.toString());
    newImageForm.append('project',this.Project.id.toString());
    this._adminService.updateImage(newImageForm,this.Image.id).subscribe( res =>{
      let auximage = this.Project.images.find(image => image.id == res.id);
      this.Project.images[this.Project.images.indexOf(auximage)] = res;
      this.setNewImage;
    });
  }
  delete_image(){
    this._adminService.deleteImage(this.Image.id).subscribe( res =>{
      let auximage = this.Project.images.find(image => image.id == this.Image.id);
      this.Project.images.splice(this.Project.images.indexOf(auximage),1);
      
    });
  }

  set_preupdate_image(img){
    this.project_image_url = img.image;
    this.Image = Object.assign({},img);
    this.create_update_img = "update";
  }

  set_deletable_image(img){
    this.Image = Object.assign({},img);
  }

  arrayOne(n){
    return Array(n);
  }

}
