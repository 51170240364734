<br><br><br>
<div class="row justify-content-sm-center">
    <div class="col-md-12">
        <!-- Disciplines component -->
        <div class="row">
            <div class="col-sm-6">
                <h2>Disciplines</h2>
                <br><br>

                <div class="row">
                    <div class="col-sm-12 card disc-card" *ngFor="let disc of Disciplines | orderBy: 'order'" [ngStyle]="{'border': Discipline.id == disc.id?'solid 3px blue':''}" (click)="select_discipline(disc)">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4"><img [src]="disc.image" width="100%"></div>
                                    <div class="col-sm-5">
                                        <h3>{{disc.name}}</h3>
                                        <h4>{{disc.shortname}}</h4>
                                        <p class="card-text">{{disc.description}}</p>
                                    </div>
                                    <div class="col-sm-3">
                                        <a (click)="set_preupdate_discipline(disc)" data-toggle="modal" data-target="#discipline-mod" class="btn btn-primary">edit</a>
                                        <br><a (click)="set_deletable_discipline(disc)" data-toggle="modal" data-target="#delete-mod" class="btn btn-danger">delete</a>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-sm-12">
                        <a class="btn btn-success" href="" data-toggle="modal" (click)="setNewDiscipline()" data-target="#discipline-mod">Add discipline</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <h2>Skills</h2>
                <br><br>
                <div class="card" *ngFor="let skill of Discipline.skills">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                {{skill.name}} 
                            </div>
                            <div class="col-sm-4">
                                <div style="width:15px;height:15px;display:inline-block;background-color:#245C81;margin-right:2px;" *ngFor="let i of arrayOne(skill.exp)"></div>
                                <div style="width:15px;height:15px;display:inline-block;background-color:#00A99D;margin-right:2px;" *ngFor="let i of arrayOne(skill.com)"></div>
                                <div style="width:15px;height:15px;display:inline-block;background-color:#FC4F00;margin-right:2px;" *ngFor="let i of arrayOne(skill.enj)"></div> 
                            </div>
                            <div class="col-sm-4">
                                <a class="btn btn-primary" data-toggle="modal" data-target="#skill-mod" (click)="set_preupdate_skill(skill)">edit</a> 
                                <a class="btn btn-danger" data-toggle="modal" data-target="#skill-delete-mod" (click)="set_deletable_skill(skill)">delete</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" >
                    
                </div>
                <br>
                <div class="row" [hidden]="!Discipline.id">
                    <div class="col-sm-12">
                        <a class="btn btn-success" data-toggle="modal" (click)="setNewSkill()" data-target="#skill-mod">Add Skill</a>
                    </div>
                </div>

            </div>
        </div>
        <br><br>
        
    </div>
</div>
<!-- ############################ MODALS ################################# -->

<div class="modal fade" id="discipline-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Discipline</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-6">Name</div>
                <div class="col-sm-6"><input required type="text" name="name" [(ngModel)]="Discipline.name" ></div>
            </div>
            <div class="row">
                <div class="col-sm-6">Shortname</div>
                <div class="col-sm-6"><input required type="text" name="name" [(ngModel)]="Discipline.shortname" ></div>
            </div>
            <div class="row">
                <div class="col-sm-6">Order</div>
                <div class="col-sm-6"><input required type="text" name="name" [(ngModel)]="Discipline.order" ></div>
            </div>
            <div class="row">
                <div class="col-sm-6">Description</div>
                <div class="col-sm-6"><textarea name="" [(ngModel)]="Discipline.description" id="" cols="30" rows="10"></textarea></div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    Image
                </div>
                <div class="col-sm-6">
                    <a class="btn btn-outline-info" onclick="document.getElementById('discipline_file').click()">
                        Choose File
                    </a>
                    <input type="file" #file (change)="previewFile(file.files)" id="discipline_file" accept="image/png, image/jpeg" style="display:none"/>
                    <img [src]="discipline_image_url" width="350px">
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_discipline()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Discipline</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar la disciplina {{Discipline.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_discipline()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="skill-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Skill</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-6">Name</div>
                <div class="col-sm-6"><input required type="text" name="name" [(ngModel)]="Skill.name" ></div>
            </div>
            <div class="row">
                <div class="col-sm-6">Experience</div>
                <div class="col-sm-6">
                    <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" (click)="Skill.exp=i" [ngStyle]="{'border': Skill.exp==i?'solid 3px blue':'solid thin #777'}" style="width:30px;height:30px;border:solid thin #777;display:inline-block">
                    {{i}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">Comfort</div>
                <div class="col-sm-6">
                    <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" (click)="Skill.com=i" [ngStyle]="{'border': Skill.com==i?'solid 3px blue':'solid thin #777'}" style="width:30px;height:30px;border:solid thin #777;display:inline-block">
                    {{i}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">Enjoyment</div>
                <div class="col-sm-6">
                    <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" (click)="Skill.enj=i" [ngStyle]="{'border': Skill.enj==i?'solid 3px blue':'solid thin #777'}" style="width:30px;height:30px;border:solid thin #777;display:inline-block">
                    {{i}}
                    </div> 
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">Order</div>
                <div class="col-sm-6"><input required type="text" name="name" [(ngModel)]="Skill.order" ></div>
            </div>
            <div class="row">
                <div class="col-sm-6">Description</div>
                <div class="col-sm-6"><textarea name="" [(ngModel)]="Skill.description" id="" cols="30" rows="10"></textarea></div>
            </div>
            <div class="row">
                
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_skill()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="skill-delete-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Skill</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar esta habilidad {{Skill.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_skill()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>