import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisciplinesComponent } from './disciplines/disciplines.component';
import { AppRoutingModule} from './app-routing.module';
//import { Feathers } from './services/feathers.service';
import { BackendLinkService } from './services/backend-link.service';
import { AuthService } from './services/auth.service';
import { AdminService } from './services/admin.service';
import { MainComponent } from './main/main.component';
import { AdminComponent } from './admin/admin.component';
import { HttpClientModule } from '@angular/common/http';
import { StorageServiceModule } from "ngx-webstorage-service";
import { ExperienceComponent } from './experience/experience.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OrderModule } from 'ngx-order-pipe';
import { MainService } from './services/main.service';
import { ExpPipe } from './pipes/exp.pipe';
import { ImgPipe } from './pipes/img.pipe';
import { ProjectsComponent } from './projects/projects.component';
import { AceModule } from 'ngx-ace-wrapper';
import { ACE_CONFIG } from 'ngx-ace-wrapper';
import { AceConfigInterface } from 'ngx-ace-wrapper';
import { HomeComponent } from './home/home.component';
import { LightboxModule } from 'ngx-lightbox';
import { ScrapComponent } from './scrap/scrap.component';
import { SociocraciaComponent } from './sociocracia/sociocracia.component';

//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const DEFAULT_ACE_CONFIG: AceConfigInterface = {
};
@NgModule({
  declarations: [
    AppComponent,
    DisciplinesComponent,
    MainComponent,
    AdminComponent,
    ExperienceComponent,
    PortfolioComponent,
    ExpPipe,
    ImgPipe,
    ProjectsComponent,
    HomeComponent,
    ScrapComponent,
    SociocraciaComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    OrderModule,
    AceModule,
    LightboxModule
  ],
  providers: [
    //Feathers,
    BackendLinkService,
    AuthService,
    AdminService,
    StorageServiceModule,
    MainService,
    {provide: ACE_CONFIG,
    useValue: DEFAULT_ACE_CONFIG}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
