<div class="row">
    <div class="col-sm-4">
        <h4>Estructura sociocrática</h4>
        <div style="margin-top:100px">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: nested_structure }"></ng-container>
        </div>

        <ng-template #recursiveListTmpl let-list="list">
            <div class="tree-level" *ngFor="let item of list">
                <div class="list-item" [ngClass]="{'hovered':hoveredId==item.id}">
                    <a class="btn btn-default" (mouseenter)="highlightItem(item)" (mouseleave)="unhighlightItem(item)" (click)="select(item)">{{ item.name }}</a>
                    <a class="add-sign" (click)="select(item)" data-toggle="modal" data-target="#exampleModal">+</a>
                </div>
                <div *ngIf="item.children" id="collapse{{item.id}}">
                    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.children }"></ng-container>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="col-sm-8">
        <canvas id="cv1" style="width:100%;height:100%"></canvas>
    </div>
</div>

<!--
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>
-->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar círculo en: {{selectedItem.name}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <input type="text"  [(ngModel)]="new_circle.name" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="saveNew()" data-dismiss="modal">Guardar</button>
      </div>
    </div>
  </div>
</div>