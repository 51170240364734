import { Component, OnInit, HostListener  } from '@angular/core';
import { Project, Path, PointText, Point } from 'paper';
import * as paper from 'paper';

@Component({
  selector: 'app-sociocracia',
  templateUrl: './sociocracia.component.html',
  styleUrls: ['./sociocracia.component.css']
})
export class SociocraciaComponent implements OnInit {

  @HostListener("mousewheel", ['$event']) onMouseScroll(event){
    if(event.altKey) {
      var newZoom = paper.view.zoom; 
		  var oldZoom = paper.view.zoom;
      if (event.deltaY > 0) {			
        newZoom = paper.view.zoom * 1.05;
      } else {
        newZoom = paper.view.zoom * 0.95;
      }
      
      var beta = oldZoom / newZoom;
      
      var mousePosition = new paper.Point(event.offsetX, event.offsetY);
      
      //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
      var viewPosition = paper.view.viewToProject(mousePosition);
      
      var mpos = viewPosition;
      var ctr = paper.view.center;
      
      var pc = mpos.subtract(ctr);
      var offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);	
      
      paper.view.zoom = newZoom;
      paper.view.center = paper.view.center.add(offset);
      
      event.preventDefault();
      //paper.view.draw();
    }
  };

  constructor() {
  }

  cv1;
  view;
  hoveredId:number;
  borderColor = "#ccc";
  activeBorderColor = "#0099ff";
  borderWidth = 5;
  selectedItem = {id:0,name:"",children:[]};
  new_circle = {id:0,name:"nuevo círculo"};
  paths = [];

  structure = {id:1,name:"Plenaria",children:[
      {id:2,name:"Coordinación",children:[
        {id:4,letter:"A",name:"Programa"},
        {id:5,letter:"B",name:"Educacion"},
        {id:6,letter:"C",name:"Feminista"},
        {id:7,letter:"D",name:"Comunicación"},
        {id:8,letter:"E",name:"Finanzas"},
        {id:9,letter:"F",name:"Gobernanza"},
        {id:10,letter:"G",name:"Territorial"}
      ]},
      {id:3,name:"Consejo"}
    ]}
  ;
  nested_structure = [this.structure];

  ngOnInit(): void {
    window['paper'] = paper;
    this.cv1 = new Project('cv1');
    this.renderTree();
    //console.log();
    /*var path = new Path.Circle({
       center: view.center,
       radius: 30,
       strokeColor: 'black'
    });

    path.onMouseEnter = function(event){
      this.fillColor="red";
    }
    path.onMouseLeave = function(event){
      this.fillColor="white";
    }*/
  }

  public lastID(branch,maxid):number{
    maxid = branch.id>maxid?branch.id:maxid;
    if(branch.children){
      branch.children.forEach(child=>{
        var childrenmaxid = this.lastID(child,maxid);
        maxid = childrenmaxid>maxid?childrenmaxid:maxid;
      });
    }else{
      maxid = branch.id>maxid?branch.id:maxid;
    }
    return maxid;
  }

  public renderTree():void{
    this.cv1.activeLayer.removeChildren();
    this.cv1.clear;
    this.paths = [];
    var view = paper.view;
    //console.log(view.center);
    var radio_min = 45; 
    var dl = this.deepestLevel(this.structure,0,0);
    var zero_radios_i = new Array(dl+1).fill(0);
    zero_radios_i[0]=radio_min;
    var zero_radios_o = new Array(dl+1).fill(0);
    zero_radios_o[0]=radio_min;
    var radios = this.radiosTree(this.structure,0,dl,zero_radios_i,zero_radios_o,false);
    var center = view.center;
    paper.view.zoom = paper.view.zoom*0.75;
    this.socioTree([center.x,center.y],this.structure,0,dl,radios);
    
    // Define a mousedown and mousedrag handler
    var downPoint;
    view.onMouseDown = function(event) {
      //console.log(event);
      downPoint = event.point;
    }

    view.onMouseDrag = function(event) {
      //console.log(event);
      var pan_offset = event.point.subtract(downPoint);
      paper.view.center = paper.view.center.subtract(pan_offset);
    }
    
    view.onMouseUp = function(event) {
    }
  }

  public select(item):void{
    this.selectedItem = item;
    var path = this.paths.find(obj=>{return obj.data.id==item.id;});
    //console.log(path);
    path.strokeColor = "#0099ff";
  }
  public saveNew():void{
    var new_id = this.lastID(this.structure,0);
    this.new_circle.id = new_id+1;

    if(!this.selectedItem.children){
      this.selectedItem.children = [];
    }
    //console.log(this.new_circle);
    this.selectedItem.children.push(this.new_circle);
    this.selectedItem = {id:0,name:"",children:[]};
    this.new_circle = {id:0,name:"nuevo círculo"};
    this.renderTree();
    //console.log(this.structure);
  }

  public highlightItem(item):void{
    var path = this.paths.find(obj=>{return obj.data.id==item.id;});
    path.strokeColor = "#0099ff";
  }
  public unhighlightItem(item):void{
    var path = this.paths.find(obj=>{return obj.data.id==item.id;});
    path.strokeColor = "#ccc";
  }

  //primero hay que encontrar el nivel más profundo
  public radiosTree(branch,level,maxlevel,radios_i,radios_o,vuelta):any{
    //console.log(level);
    //console.log(maxlevel);
    var ilevel = level+1;
    if(level==maxlevel){ // si llego al ultimo nivel, me empiezo a devolver  
      return {vuelta:true,radios_i:radios_i,radios_o:radios_o};;
    }else{ //si aun no llego, voy más adentro
      if(branch.children){
        branch.children.forEach(child =>{
          var aux = this.radiosTree(child,ilevel,maxlevel,radios_i,radios_o,vuelta);
          vuelta = aux.vuelta;
          radios_i = aux.radios_i;
          radios_o = aux.radios_o;
        });
      }
    }
    if(vuelta){ //maxlevel es 2
      //console.log(ilevel);
      if(branch.children){
        var aux_ri = this.radio_i(radios_o[maxlevel-ilevel],10,branch.children.length);
        //console.log(maxlevel-(ilevel+1));
        //console.log(radios_o[maxlevel-(ilevel+1)]);
        var aux_ro = aux_ri + radios_o[maxlevel-ilevel] + 50;
        
        if(aux_ro > radios_o[maxlevel-(ilevel-1)]){
          
          radios_i[maxlevel-(ilevel-1)] = aux_ri;
          radios_o[maxlevel-(ilevel-1)] = aux_ro;
        }
      }
    }
    if(level==0){
      //dibujo la raíz (que puede ser múltiple)
    }
    return {vuelta:true,radios_i:radios_i,radios_o:radios_o};
  }

  public deepestLevel(branch,level,maxlevel):any{
    //console.log(level);
    //console.log(maxlevel);
    var ilevel = level+1;
    
    if(branch.children){
      var imax;
      imax = maxlevel;
      branch.children.forEach(child => {
        imax = this.deepestLevel(child,ilevel,imax);
      });
      return imax;
    }else{
      if(level>maxlevel){
        return level;
      }else{
        return maxlevel;
      }
    }
  }

  public socioTree(center,branch,level,maxlevel,radios):any{
    var ilevel = level+1;
    console.log(level);
    
    if(level==0){ //sólo si es el primero dibujo la raíz
      console.log(radios.radios_o[radios.radios_o.length-1]);
      var path = new Path.Circle({
        center: center,
        radius: radios.radios_o[radios.radios_o.length-1],
        strokeColor: this.borderColor,
        strokeWidth: this.borderWidth,
        fillColor: "white",
        data:{id:branch.id}
      });
      this.paths.push(path);
      var that = this;
      path.onMouseEnter = function(event){
        this.strokeColor= "#0099ff";
        that.hoveredId = this.data.id;
      }
      path.onMouseLeave = function(event){
        //this.fillColor="white";
        this.strokeColor = "#ccc";
        that.hoveredId = 0;
      }
      var text = new PointText({
        point: [center[0], center[1]+radios.radios_o[radios.radios_o.length-1]-20],
        content: branch.name,
        justification:"center", 
        fillColor: 'black',
        fontFamily: 'Courier New',
        fontWeight: 'bold',
        fontSize: 12
      });

    }

    var radio_i = radios.radios_i[maxlevel-level];
    var radio_o = radios.radios_o[maxlevel-level];
    if(branch.children){
      
      var child_center;

      if(branch.children.length>1){ //si hay mas de un hijo, los hacemos orbitar
        var n = branch.children.length;
        var paso_alpha = 2*Math.PI/n;
        var i_alpha = 0;
        branch.children.forEach(child =>{
          child_center = [center[0]+radio_i*Math.cos(i_alpha),center[1]+radio_i*Math.sin(i_alpha)];
          var child_radio
          if(child.children){
            child_radio = radios.radios_o[maxlevel-(level+1)]
          }else{
            child_radio = radios.radios_o[0];
          }
          var path = new Path.Circle({
            center: child_center,
            radius: child_radio,
            strokeColor: this.borderColor,
            strokeWidth: this.borderWidth,
            fillColor:"white",
            data:{id:child.id}
          });
          this.paths.push(path);
          var that = this;
          path.onMouseEnter = function(event){
            this.strokeColor= "#0099ff";
            that.hoveredId = this.data.id;
          }
          path.onMouseLeave = function(event){
            //this.fillColor="white";
            this.strokeColor = "#ccc";
            that.hoveredId = 0;
          }
          var text = new PointText({
            point: [child_center[0], child_center[1]+child_radio+15],
            content: child.name,
            justification:"center", 
            fillColor: 'black',
            fontFamily: 'Courier New',
            fontWeight: 'bold',
            fontSize: 12
          });
          i_alpha += paso_alpha;
          this.socioTree(child_center,child,ilevel,maxlevel,radios);
        });
      }else{ //si hay un sólo hijo entonces tiene que ser concéntrico
        child_center = [center[0],center[1]];
        var path = new Path.Circle({
          center: child_center,
          radius: radios.radios_o[maxlevel-(level+1)],
          strokeColor: 'black'
        });
        this.socioTree(child_center,branch.children[0],ilevel,maxlevel,radios);
      }
    }

  }

  public radio_i(R,d,n){
    if(n>2){
      return ( ((d/2+R)/(Math.tan(Math.PI/n)))**2 + (d/2+R)**2 )**0.5;
    }else{
      return R+d/2;
    }
  }
}

//console.log(level);
    //console.log(maxlevel);
    /*var ilevel = level+1;
    if(level==maxlevel){ // si llego al ultimo nivel, me empiezo a devolver  
      return true;
    }else{ //si aun no llego, voy más adentro
      branch.children.forEach(child =>{
        vuelta = this.socioTree(child,ilevel,maxlevel,vuelta);
      });
    }

    if(vuelta){
      branch.children.forEach(child =>{
        //console.log(child.id);
      });
    }
    if(level==0){
      //dibujo la raíz (que puede ser múltiple)
    }
    return true;*/