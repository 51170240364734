import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-scrap',
  templateUrl: './scrap.component.html',
  styleUrls: ['./scrap.component.css']
})
export class ScrapComponent implements OnInit {

  constructor(private http: HttpClient) {

  }

  precio_lena = 50000; //pesos
  consumo_lena = 5; //m2
  COP = 2.8;
  PCI_lena = 1587; //kWh/m3
  precio_elec_dscto = 131; //pesos/kWh
  rendimiento_estufa = 0.6;
  emisiones_CO2_lena = 23.12600879; //gr/kWh
  emisiones_MP25 = 91.76988/1000; //gr/kWh
  emisiones_CO2_elec = 36.01445283; //gr/kWh
  formulario = {
      comuna:"Rengo",
      tipologia_vivienda:"aislada",
      anio_construccion:"SRT",
      materialidad:"I1230",
      nombre:"Pedrito los Palotes",
      altitud:0,
      direccion:"Mi casa #1234",
      rut:"12345678-9",
      email:"pedrito@mail.com",
      telefono:"+56997467328",
      superficie:50,
      n_espacios:3,
      n_dormitorios:3,
      n_comunes:1,
      n_pisos:1,
      etario:0,
      pppf:0,
      prmma:0,
      uso_lena:0,
      espacios:[
        {nombre:"dormitorio principal",superficie:30,piso:1},
        {nombre:"dormitorio niños 1",superficie:15,piso:1},
        {nombre:"dormitorio niños 2",superficie:5,piso:1},
        {nombre:"dormitorio visitas 1",superficie:5,piso:1},
        {nombre:"dormitorio visitas 2",superficie:5,piso:1}
      ]
  }

  potencias = [9000,12000,18000,22000,24000];
  respuesta;
  backend_root = "https://harfagar.com/calefaccion_residencial/backend/router.php"

  ngOnInit(): void {
  }

  change_consumo(event){
    this.consumo_lena = event.value;
  }
  change_precio(event){
    this.precio_lena = event.value;
  }
  formatNumber(number:number,fractionSize:number):string{
    var aux,n;
    if(number){
      if(number>=100000){
        n = number/10000;
        aux = Math.round(n)*10000;
      }
      if(number>=10000){ //12537 / 1000
        n = number/1000;
        aux = Math.round(n)*1000;
      }
      if(number>=1000 && number <10000){ //1587 / 1000
        n = number/100;
        aux = Math.round(n)*100;
      }
      if(number>=100 && number <1000){ //873 / 1000
        n = number/10;
        aux = Math.round(n)*10;
      }
      if(number<100){
        n = fractionSize*10;
        aux = n>0?Math.round(number*n)/n:Math.round(number);
      }
      //console.log(aux.toLocaleString('es-CL', {maximumFractionDigits: fractionSize}));
      //console.log(String(aux).replace(".",","));
      var semifinal = String(aux).replace(".",",");
      var th = Math.floor((semifinal.length-1)/3); // 6 --> 1 // 4 --> 1
      var final = "";
      for (let i = semifinal.length - 3; i > 0 ; i-=3) { //100000 // 1000000
        final = "." + semifinal.substr(i,3) + final;
      }
      final = semifinal.substr(0,semifinal.length-Math.floor(Math.log(aux)/Math.log(1000))*3) + final;
      return final;
    }else{
      return "";
    }
  }

  get_equipos(){
    /*$http({
            method:"post",
            url:API_URL+"/router.php",
            data:JSON.stringify({action:"get_equipos",formulario:formulario})
        }).then(function(response){
            $scope.calculado = true;
            $scope.equipos = response.data.respuesta.equipos;
            $scope.espacios = response.data.respuesta.espacios;
            $scope.demandas = response.data.respuesta.energia;
            $scope.potencia_total = 0;
            $scope.espacios.forEach(esp => {
                $scope.potencia_total+=esp.pot;
            });

        });*/
        var data = {
          action:"get_equipos",
          formulario:this.formulario
        }
        this.http.post(this.backend_root,data).subscribe((response:any)=>{
          this.respuesta = response.respuesta;
        });

  }


}
