import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {AdminService} from '../services/admin.service';
import { DisciplinesComponent } from '../disciplines/disciplines.component';
import 'brace';
import 'brace/mode/html';
import 'brace/theme/chrome';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  private storage;

  constructor(private _authService: AuthService, private _adminService: AdminService) {}
  
  public user: any;
  public login_status: boolean = false;
  

  ngOnInit(): void {
    this._authService.isLogued();
    this._authService.get_login.subscribe(data => {
      this.login_status = data;      
    })
    this.user = {username:"",password:""};
  }
  
  /*headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this._userService.token
  })*/

  login() {
    this._authService.login({'username': this.user.username, 'password': this.user.password});
  }
 
  refreshToken() {
    this._authService.refreshToken();
  }
 
  logout() {
    this._authService.logout();
    location.reload();
  }

}
