<br><br>
<div class="row justify-content-sm-center" [hidden]="login_status">
    <div class="col-sm-4">
    <div class="card">
        <div class="card-body">
            <input type="text" class="form-control" placeholder="username" [(ngModel)]="user.username" name="username"><br>
            <input type="password" class="form-control" placeholder="password" [(ngModel)]="user.password" name="password"><br>
            <br>
            <a (click)="login()" class="btn btn-primary">Login</a>
        </div>
    </div>
    </div>
</div>

<div class="row justify-content-sm-center" [hidden]="!login_status">
    <br>
    <a (click)="logout()" class="btn btn-dark">Logout</a>
    <br>
    <div class="col-sm-12">
        <app-disciplines></app-disciplines>
        <app-experience></app-experience>
        <app-projects></app-projects>
    </div>
</div>
