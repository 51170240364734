import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'imgfilter',
    pure: false
})
export class ImgPipe implements PipeTransform {
    transform(content: string, imgs: any[]): any {
        let body = content;
        //for (let i = 0; i < imgs.length; i++) {
        if(body){
            let aux_match = body.match(/\[imgs\(([0-9])*,([0-9]*),([0-9]*)\)\]/g);
            console.log(aux_match);
            if(aux_match){
                let k = 1;
                aux_match.forEach(img=>{
                    let params = img.match(/\[imgs\(([0-9])*,([0-9]*),([0-9]*)\)\]/);
                    let i = parseInt(params[1]);
                    let aux_str = "";
                    if(imgs[i]){
                        aux_str = "<div class='project_img_holder image-"+i+"'><img width='"+params[2]+"' height='"+params[3]+"' src='"+imgs[i].image+"' ><br><p> Fig "+k+". "+imgs[i].name+"</p></div>"
                        //console.log(aux_str);
                    }
                    //console.log(body.replace(params[0],aux_str));
                    body = body.replace(String(params[0]),aux_str);
                    k = k+1;
                });
            }
        }
        //}
        return body;
    }
}