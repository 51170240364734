import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of,BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from './auth.service';
//import { Discipline } from '../discipline';
import { Skill } from '../skill';
import { Experience } from '../experience';
import { Workplace } from '../workplace';
import { Tag } from '../tag';

@Injectable()
export class MainService {
    public httpOptions;
    public log = {};
    //public login_status:BehaviorSubject<boolean> = new BehaviorSubject(false);
    public data:BehaviorSubject<any> = new BehaviorSubject({});
    get_data = this.data.asObservable();
    
    backend_root:string;
    constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) storage: StorageService, private _authService:AuthService) {
      this.backend_root = this._authService.backend_root;
      this.httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            })
        };
      
    }
    
    //############## DISCIPLINES #######################

    public getAll():Observable<any>{
        return this.http.get<any>(this.backend_root + '/publics/', this.httpOptions as Object);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
        
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
        
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}