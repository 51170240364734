import { Component, OnInit } from '@angular/core';
import {Discipline} from "../discipline";
import {Skill} from "../skill";
import {AuthService} from '../services/auth.service';
import {AdminService} from '../services/admin.service';
//import {DISCIPLINES} from '../mock-disciplines';

@Component({
  selector: 'app-disciplines',
  templateUrl: './disciplines.component.html',
  styleUrls: ['./disciplines.component.css']
})

export class DisciplinesComponent implements OnInit {
  public Discipline: Discipline = {name:"",shortname:""};
  public Disciplines: Discipline[] = [];
  public Skill: Skill = {name:"",exp:0,com:0,enj:0};
  public Skills: Skill[];
  public create_update_disc = "create";
  public create_update_skil = "create";
  public discipline_image_url;
  public discipline_img;
  public imagePath;
  constructor(private _adminService:AdminService,_authService:AuthService) { 
    _authService.get_login.subscribe(data => {
      console.log(data);
      if(data==true){
        this.getAll();
      }      
    });
  }
  



  ngOnInit(): void {
    
  }

  public getAll():void{
    this._adminService.getDisciplines().subscribe(disciplines => this.Disciplines = disciplines);
  }

  setNewDiscipline(){
    this.Discipline = {name:"",shortname:""};
    this.discipline_image_url = "";
    this.create_update_disc = "create";
  }
  previewFile(files){
    if (files.length === 0)
      return;
  
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.discipline_img = files[0];
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => {
      this.discipline_image_url = reader.result; 
    }
  }

  create_update_discipline(){
    if(this.create_update_disc == "create"){
      this.create_discipline();
    }
    if(this.create_update_disc == "update"){
      this.update_discipline();
    }
  }
  
  create_discipline(){
    const newDisciplineForm = new FormData();
    newDisciplineForm.append('image', this.discipline_img, this.discipline_img.name);
    newDisciplineForm.append('name',this.Discipline.name);
    newDisciplineForm.append('shortname',this.Discipline.shortname);
    newDisciplineForm.append('order',this.Discipline.order.toString());
    newDisciplineForm.append('description',this.Discipline.description);
    this._adminService.createDiscipline(newDisciplineForm).subscribe( res =>{
      this.Disciplines.push(res);
    });
  }
  update_discipline(){
    const newDisciplineForm = new FormData();
    if(this.discipline_img != "" && this.discipline_img != null){
      newDisciplineForm.append('image', this.discipline_img, this.discipline_img.name);
    }
    newDisciplineForm.append('id',this.Discipline.id.toString());
    newDisciplineForm.append('name',this.Discipline.name);
    newDisciplineForm.append('shortname',this.Discipline.shortname);
    newDisciplineForm.append('order',this.Discipline.order.toString());
    newDisciplineForm.append('description',this.Discipline.description);

    this._adminService.updateDiscipline(newDisciplineForm,this.Discipline.id).subscribe( res =>{
      let auxdisc = this.Disciplines.find(disc => disc.id == res.id);
      this.Disciplines[this.Disciplines.indexOf(auxdisc)] = res;
      this.setNewDiscipline;
    });
  }

  delete_discipline(){
    this._adminService.deleteDiscipline(this.Discipline.id).subscribe( res =>{
        let auxdisc = this.Disciplines.find(disc => disc.id == this.Discipline.id);
        this.Disciplines.splice(this.Disciplines.indexOf(auxdisc),1);
    });
  }
  
  set_preupdate_discipline(disc){
    this.Discipline = Object.assign({},disc);
    this.discipline_image_url = disc.image;
    this.create_update_disc = "update";
  }

  set_deletable_discipline(disc){
    this.Discipline = Object.assign({},disc);
  }

  select_discipline(disc){
    this.Discipline = disc;
  }

  create_update_skill(){
    if(this.create_update_skil == "create"){
      this.create_skill();
    }
    if(this.create_update_skil == "update"){
      this.update_skill();
    }
  }

  setNewSkill(){
    this.Skill = {name:""};
    this.create_update_skil = "create";
  }

  create_skill(){
    this.Skill.discipline_id = this.Discipline.id;
    //console.log(this.Skill);
    this._adminService.createSkill(this.Skill).subscribe( res =>{
      this.Discipline.skills.push(res);
    });
  }
  update_skill(){
    this._adminService.updateSkill(this.Skill,this.Skill.id).subscribe( res =>{
      let auxskill = this.Discipline.skills.find(skill => skill.id == res.id);
      this.Discipline.skills[this.Discipline.skills.indexOf(auxskill)] = res;
      this.setNewSkill;
    });
  }
  delete_skill(){
    this._adminService.deleteSkill(this.Discipline.id).subscribe( res =>{
      let auxskill = this.Discipline.skills.find(skill => skill.id == this.Skill.id);
      this.Discipline.skills.splice(this.Discipline.skills.indexOf(auxskill),1);
      
    });
  }

  set_preupdate_skill(skil){
    this.Skill = Object.assign({},skil);
    this.create_update_skil = "update";
  }

  set_deletable_skill(skil){
    this.Skill = Object.assign({},skil);
  }

  arrayOne(n){
    return Array(n);
  }

}
