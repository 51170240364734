import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Experience} from "../experience";
import {Workplace} from "../workplace";
import {Tag} from "../tag";
import {Url} from "../url";
import {AuthService} from '../services/auth.service';
import {AdminService} from '../services/admin.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'yyyy-mm-dd',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class ExperienceComponent implements OnInit {
  public visible = true;
  public selectable = true;
  public removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  public Experience: Experience = {name:"",tags:[]};
  public Experiences: Experience[];
  public create_update_expe = "create";

  public Workplace: Workplace = {name:""};
  public Workplaces: Workplace[] = [];
  public create_update_work = "create";

  public tagCtrl = new FormControl();
  public filteredTags:Observable<string[]>;
  public allTags:string[] = [];

  public new_url:Url = {text:"",link:""};

  ngOnInit(): void {
    //this._adminService.getExperiences().subscribe(experiences => this.Experiences = experiences);
    
  }

  // ############ TAG AUTOCOMPLETE #################################################

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  constructor(private _adminService:AdminService, _authService:AuthService) {
    _authService.get_login.subscribe(data => {
      if(data==true){
        this._adminService.getWorkplaces().subscribe(workplaces => this.Workplaces = workplaces);
      }      
    });
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  add_new_tag(){
    if(!this.Experience.urls){
      this.Experience.urls = [];
    }
    this.Experience.urls.push(this.new_url);
    this.new_url = {text:"",link:""};
  }
  remove_tag(url){
    this.Experience.urls.splice(this.Experience.urls.indexOf(url,1));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.Experience.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.Experience.tags.indexOf(tag);
    if (index >= 0) {
      this.Experience.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.Experience.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  // ########################### EXPERIENCE ###################################

  setNewWorkplace(){
    this.Workplace = {name:""};
    this.create_update_work = "create";
  }

  create_update_workplace(){
    if(this.create_update_work == "create"){
      this.create_workplace();
    }
    if(this.create_update_work == "update"){
      this.update_workplace();
    }
  }
  
  create_workplace(){
    this.Workplace.date_ini = _moment(this.Workplace.date_ini).format("yyyy-MM-DD")
    this.Workplace.date_end = _moment(this.Workplace.date_end).format("yyyy-MM-DD")
    this._adminService.createWorkplace(this.Workplace).subscribe( res =>{
      this.Workplaces.push(res);
    });
  }

  update_workplace(){
    this._adminService.updateWorkplace(this.Workplace,this.Workplace.id).subscribe( res =>{
      let auxdisc = this.Workplaces.find(disc => disc.id == res.id);
      this.Workplaces[this.Workplaces.indexOf(auxdisc)] = res;
      this.setNewWorkplace;
    });
  }

  delete_workplace(){
    this._adminService.deleteWorkplace(this.Workplace.id).subscribe( res =>{
        let auxdisc = this.Workplaces.find(disc => disc.id == this.Workplace.id);
        this.Workplaces.splice(this.Workplaces.indexOf(auxdisc),1);
    });
  }
  
  set_preupdate_workplace(disc){
    this.Workplace = Object.assign({},disc);
    this.create_update_work = "update";
  }

  set_deletable_workplace(disc){
    this.Workplace = Object.assign({},disc);
  }

  select_workplace(disc){
    this.Workplace = disc;
  }

  // ########################### EXPERIENCE ###################################

  setNewExperience(){
    this.Experience = {name:"",tags:[]};
    this.create_update_expe = "create";
    this._adminService.getTags().subscribe(data=>{this.allTags = data});
  }

  create_update_experience(){
    if(this.create_update_expe == "create"){
      this.create_experience();
    }
    if(this.create_update_expe == "update"){
      this.update_experience();
    }
  }
  
  create_experience(){
    this.Experience.date_ini = _moment(this.Experience.date_ini).format("yyyy-MM-DD");
    this.Experience.date_end = _moment(this.Experience.date_end).format("yyyy-MM-DD");
    this.Experience.workplace = this.Workplace.id;
    this._adminService.createExperience(this.Experience).subscribe( res =>{
      this.Workplace.experiences.push(res);
    });
  }

  update_experience(){
    this._adminService.updateExperience(this.Experience,this.Experience.id).subscribe( res =>{
      //console.log(res.id);
      //console.log(this.Experiences);
      let auxdisc = this.Workplace.experiences.find(disc => disc.id == res.id);
      this.Workplace.experiences[this.Workplace.experiences.indexOf(auxdisc)] = res;
      this.setNewExperience;
    });
  }

  delete_experience(){
    this._adminService.deleteExperience(this.Experience.id).subscribe( res =>{
        let auxdisc = this.Workplace.experiences.find(disc => disc.id == this.Experience.id);
        this.Workplace.experiences.splice(this.Workplace.experiences.indexOf(auxdisc),1);
    });
  }
  
  set_preupdate_experience(disc){
    this.Experience = Object.assign({},disc);
    this.create_update_expe = "update";
    this._adminService.getTags().subscribe(data=>{this.allTags = data});
  }

  set_deletable_experience(disc){
    this.Experience = Object.assign({},disc);
  }

  arrayOne(n){
    return Array(n);
  }

}

