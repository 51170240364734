import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of,BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class AuthService {

  // http options used for making API calls
  private httpOptions: any;
  private storage;
  // the actual JWT token
  public token: string;
  public login_status:BehaviorSubject<boolean> = new BehaviorSubject(false);
  get_login = this.login_status.asObservable();
  // the token expiration date
  public token_expires: Date;

  // the username of the logged in user
  public username: string;

  // error messages received from the login attempt
  public errors: any = [];

  public backend_root = "https://django--sven.harfagar.com";

  constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) storage: StorageService) {
    this.storage = storage;
    this.token = this.storage.get("session_token") || "";
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + this.token
      })
    };
    //console.log(this.httpOptions);
  }

  public isLogued():void{
    this.http.get(this.backend_root + '/authverifier/' , this.httpOptions).subscribe(
      data=>{
        this.login_status.next(true);
    },
      error =>{
        console.log(error)
      }
    )

  }
  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint

  public login(user):void {
    this.http.post(this.backend_root + '/api-token-auth/', JSON.stringify(user), this.httpOptions).subscribe(
      data => {

        this.updateData(data['token']);
        this.login_status.next(true);
      },
      err => {
        this.errors = err['error'];
      }
    );;
  }

  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    this.http.post(this.backend_root +'/api-token-refresh/', JSON.stringify({token: this.token}), this.httpOptions).subscribe(
      data => {
        this.updateData(data['token']);
      },
      err => {
        this.errors = err['error'];
      }
    );
  }

  public logout() {
    this.token = null;
    this.token_expires = null;
    this.username = null;
    //console.log(this.storage);
    this.storage.remove("session_token");
    location.reload;
  }

  public updateData(token) {
    this.token = token;
    this.errors = [];

    // decode the token to read the username and expiration timestamp
    const token_parts = this.token.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));
    this.token_expires = new Date(token_decoded.exp * 1000);
    this.username = token_decoded.username;
    this.storage.set("session_token",token);
  }

}
