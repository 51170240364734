<div class="container">
    <div class="row">
      <div class="col-md-3 bd-sidebar">
        <nav id="navegador" class="navbar navbar-light bg-light">
          <div class="collapse max-width" id="menu_collapse">
            <div *ngIf="!urlActive('portfolio')">
              <a class="nav-link" href="#greetings">Greetings</a>
              <a class="nav-link" href="#disciplines">Skills</a>
              <nav class="nav flex-column sub-menu">
                <a class="nav-link" *ngFor="let disc of disciplines | orderBy:'order'" href="#{{disc.shortname}}">{{disc.name}}</a>
              </nav>
              <a class="nav-link" href="#experience">Experience</a>
              <nav class="nav flex-column sub-menu">
                  <a class="nav-link" *ngFor="let work of workplaces | orderBy:'order'"  href="#{{work.shortname}}">{{work.name}}</a>
              </nav>
              <a class="nav-link" href="#others">Others</a>
              <a class="nav-link" [routerLink]="['/portfolio']">Portfolio</a>
            </div>
            <div *ngIf="urlActive('portfolio')">
              <a class="nav-link" [routerLink]="'/'">&#60; Back</a>
              <a class="nav-link">Portfolio</a>
              <nav class="nav flex-column sub-menu">
              <a class="nav-link" [ngClass]="{'active': urlActive(proj.shortname)}" *ngFor="let proj of projects | orderBy:'name'" [routerLink]="['/portfolio/'+proj.shortname]">
                <i *ngIf="!urlActive(proj.shortname)" class="fa fa-book"></i> <i *ngIf="urlActive(proj.shortname)" class="fa fa-book-reader"></i> {{proj.name}}
              </a>
              </nav>
            </div>
          </div>
          <br>
          <div class="max-width">
            <a id="menu_btn" data-toggle="collapse" href="#menu_collapse" role="button" data-target="#menu_collapse" aria-expanded="true" aria-controls="collapseOne">
              <!--<fa-icon [icon]="['fas','bars']" size="xl"></fa-icon>-->
              <i class="fa fa-bars fa-xl"></i>
            </a>
          </div>
        </nav>
      </div>

      <div class="col-md-9 contenido">
        <router-outlet></router-outlet>
      </div>

    </div>
</div>

  <!--<div id="project-viewer" [ngClass]="{'project-modal': true,'active':showingProject}">
    <div class="project-closer" (click)="closeProject()"><i class="fa fa-times-circle fa-4x"></i></div>
    <div class="row">
      <div class="col-sm-12">
        <h2>{{visibleProject.name}}</h2>
        <h4>{{visibleProject.subtitle}}</h4>
        <br>
        
        <div class="row">
          <div class="col-sm-11">
            <div class="image-vslider">
              <div class="up-btn"> <i class="fa fa-angle-left fa-4x" aria-hidden="true"></i> </div>
              <div class="vslider-mask">
                <div class="vslider-mobile" [ngStyle]="{'left': 0}">
                  <div class="project-img-wrapper" *ngFor="let img of visibleProject.images">
                    <img style="height:150px;" [src]="img.image">
                  </div>
                </div>
              </div>
              <div class="dn-btn"> <i class="fa fa-angle-right fa-4x" aria-hidden="true"></i> </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-sm-12 project-body" [innerHtml]="visibleProject.body | imgfilter:visibleProject.images">
            
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
-->