<div class="container">
    <div class="row justify-content-md-center">

        <div class="col-sm-8">
            <h3>Yo compro el metro cúbico de leña en:</h3>
            <div style="text-align:center">
                <span style="margin-right:30px;">40.000 pesos</span>
                <mat-slider style="width:500px" min="40000" thumbLabel="true" max="75000" step="1000" (input)="change_precio($event)" value="50000"></mat-slider>
                <span style="margin-left:30px;">75.000 pesos</span>
            </div>
        </div>

    </div>
    <div class="row justify-content-md-center">
            <div class="col-sm-8">
            <h3>Yo consumo en leña al año:</h3>
            <div style="text-align:center">
                <span style="margin-right:30px;">2 m<sup>3</sup></span>
                <mat-slider style="width:500px" (input)="change_consumo($event)" min="2" thumbLabel="true" max="20" step="1" value="5"></mat-slider>
                <span style="margin-left:30px;">20 m<sup>3</sup></span>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-sm-8">
            <table class="table">
                <tr>
                    <td></td>
                    <td>
                        Leña seca en estufa de doble cámara
                    </td>
                    <td>
                        Electricidad en split inverter
                    </td>
                    <td>
                        Ahorro
                    </td>
                </tr>
                <tr>
                    <td>Energía consumida [kWh al año]</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena,0)}}</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*rendimiento_estufa/COP,0)}}</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena-consumo_lena*PCI_lena*rendimiento_estufa/COP,0)}}</td>
                </tr>
                <tr>
                    <td>Costo [pesos al año]</td>
                    <td>{{formatNumber(consumo_lena*precio_lena,0)}}</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*rendimiento_estufa/COP*precio_elec_dscto,0)}}</td>
                    <td>{{
                        consumo_lena*precio_lena-consumo_lena*PCI_lena*rendimiento_estufa/COP*precio_elec_dscto>0?
                        formatNumber(consumo_lena*precio_lena-consumo_lena*PCI_lena*rendimiento_estufa/COP*precio_elec_dscto,0):
                        "Para este precio de la leña no hay ahorros"
                        }}</td>
                </tr>
                <tr>
                    <td>Emisiones CO<sub>2</sub> [kg CO<sub>2</sub> al año]</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*emisiones_CO2_lena/1000,0)}}</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*rendimiento_estufa/COP*emisiones_CO2_elec/1000,0)}}</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*emisiones_CO2_lena/1000-consumo_lena*PCI_lena*rendimiento_estufa/COP*emisiones_CO2_elec/1000,0)}}</td>
                </tr>
                <tr>
                    <td>Emisiones de Material particulado fino [kg MP<sub>25</sub> al año]</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*emisiones_MP25,0)}}</td>
                    <td>0</td>
                    <td>{{formatNumber(consumo_lena*PCI_lena*emisiones_MP25,0)}}</td>
                </tr>
            </table>
        </div>
    </div>
    <button class="btn btn-alert" (click)="get_equipos()">Calcular</button>
    <div class="row justify-content-md-center">
            <div class="col-sm-8">
            <h3>Respuesta</h3>
            <div style="text-align:center" *ngIf="respuesta" >

                <table class="table">
                    <thead>
                        <tr>
                            <td>Espacios</td>
                            <td>Equipos</td>
                            <td>Costo aproximado del equipo instalado en pesos</td>
                            <td>Gasto anual en pesos</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let esp1 of respuesta.espacios">
                            <td>
                              <a *ngFor="let esp2 of esp1.espacios" >
                                {{esp2}}
                              </a>
                            </td>
                            <td>
                              <a *ngFor="let eqp of esp1.equipos; index as i" >
                                {{eqp>=1?eqp+" equipos de "+potencias[i]+" BTU/hr":""}}
                              </a>
                            </td>
                            <td>
                              {{formatNumber(esp1.inversion[0],0)}}-{{formatNumber(esp1.inversion[1],0)}}
                            </td>
                            <td>
                              {{formatNumber(esp1.gasto*0.8,0)}}-{{formatNumber(esp1.gasto*1.2,0)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <p>
                  En total usted gastaría:
                </p>
            </div>
        </div>
    </div>
</div>
