import { Component, OnInit } from '@angular/core';
import {MainService} from '../services/main.service';
import {Discipline} from "../discipline";
import {Workplace} from "../workplace";
import {Project} from "../project";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  exp_filters:string[] = [];
  toggleImages:boolean=true;
  workplaces:Workplace[];
  disciplines:Discipline[];
  projects:Project[];
  constructor(private _mainService:MainService) { }

  ngOnInit(): void {
    this._mainService.get_data.subscribe(data=>{
      //this._mainService.data.next(data);
      this.workplaces=data.workplaces;
      this.disciplines=data.disciplines;
      this.projects=data.projects;
    });
  }
  removeFilter(filtro:string){
    this.exp_filters.splice(this.exp_filters.indexOf(filtro),1);
  }
  addFilter(filtro:string){
    if(this.exp_filters.indexOf(filtro)==-1){
      this.exp_filters.push(filtro);
    }
  }

}
