import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { MainComponent } from './main/main.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { SociocraciaComponent } from './sociocracia/sociocracia.component';
import { ScrapComponent } from './scrap/scrap.component';

const routes: Routes = [
    { path: 'admin', component: AdminComponent},
    { path: 'sociocracia', component: SociocraciaComponent },
    { path: 'scrap', component: ScrapComponent },
    { path: '', component: MainComponent , children:
    [
        { path: '', component: HomeComponent },
        { path: 'portfolio', component: PortfolioComponent },
        { path: 'portfolio/:short', component: PortfolioComponent }
      ]
    },
    
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }