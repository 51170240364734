<br><br><br>
<div class="row justify-content-sm-center">
    <div class="col-md-12">
        <div class="row">
            <!-- Workplaces code -->
            <div class="col-sm-6">
                <h2>Workplaces</h2>
                <br><br>

                <div class="row">
                    <div class="col-sm-12 card wrk-card" *ngFor="let wrk of Workplaces" [ngStyle]="{'border': Workplace.id == wrk.id?'solid 3px blue':''}" (click)="select_workplace(wrk)">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h3>{{wrk.name}}</h3>
                                        <p class="card-text">{{wrk.date_ini}}</p>
                                        <p class="card-text">{{wrk.date_ini}}</p>
                                    </div>
                                    <div class="col-sm-3">
                                        <a (click)="set_preupdate_workplace(wrk)" data-toggle="modal" data-target="#workplace-mod" class="btn btn-primary">edit</a>
                                        <br><a (click)="set_deletable_workplace(wrk)" data-toggle="modal" data-target="#delete-mod-wrk" class="btn btn-danger">delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-sm-12">
                        <a class="btn btn-success" href="" data-toggle="modal" (click)="setNewWorkplace()" data-target="#workplace-mod">Add workplace</a>
                    </div>
                </div>
            </div>

            <!-- Experiences code -->
            <div class="col-sm-6">
                <h2>Experiences</h2>
                <br><br>

                <div class="row">
                    <div class="col-sm-12 card exp-card" *ngFor="let exp of Workplace.experiences" [ngStyle]="{'border': Experience.id == exp.id?'solid 3px blue':''}">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h3>{{exp.name}}</h3>
                                        <h4>{{exp.client}}</h4>
                                        <p class="card-text">{{exp.role}}</p>
                                        <div>
                                          <a *ngFor="let tag of exp.tags;let isLast=last">{{tag}}{{isLast? '' : ', '}}</a>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <a (click)="set_preupdate_experience(exp)" data-toggle="modal" data-target="#experience-mod" class="btn btn-primary">edit</a>
                                        <br><a (click)="set_deletable_experience(exp)" data-toggle="modal" data-target="#delete-mod-exp" class="btn btn-danger">delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <br><br>
                <div class="row" [hidden]="!Workplace.id">
                    <div class="col-sm-12">
                        <a class="btn btn-success" href="" data-toggle="modal" (click)="setNewExperience()" data-target="#experience-mod">Add experience</a>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        
    </div>
</div>
<!-- ############################ EXPERIENCE MODALS ################################# -->

<div class="modal fade" id="experience-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Experience</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-3">Name</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Experience.name" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">Date ini</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input [(ngModel)]="Experience.date_ini" matInput placeholder="Date ini" [matDatepicker]="picker1">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">Date end</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input [disabled]="Experience.still_active" [(ngModel)]="Experience.date_end" matInput placeholder="Date end" [matDatepicker]="picker2">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field> <br>
                    <mat-checkbox appearance="outline" [(ngModel)]="Experience.still_active">Still active?</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">Client</div>
                <div class="col-sm-6">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Experience.client" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-6">Client's url</div>
                <div class="col-sm-6">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Experience.client_url" >
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
              <div class="col-sm-3">Links</div>
              <div class="col-sm-9">
                <table>
                  <thead>
                  <tr>
                  <th>text</th><th>link</th><th></th></tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let url of Experience.urls">
                      <td>{{url.text}}</td>
                      <td>{{url.link}}</td>
                      <td><a (click)="remove_tag(url)" style="color:red;font-weight:bolder">-</a></td>
                    </tr>
                    <tr>
                      <td>
                        <mat-form-field color="accent" appearance="outline">
                          <input type="text" matInput [(ngModel)]="new_url.text" >
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field color="accent" appearance="outline">
                          <input type="text" matInput [(ngModel)]="new_url.link" >
                        </mat-form-field>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="3">
                      <a class="btn btn-success" (click)="add_new_tag()">agregate</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


            <div class="row">
                <div class="col-sm-3">Role</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <textarea name="" [(ngModel)]="Experience.role" matInput id="" cols="30" rows="10"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">Tags</div>
                <div class="col-sm-9">
                    <mat-form-field style="width:100%" class="tag-list" appearance="outline">
                        <mat-chip-list #tagList aria-label="Tag selection">
                          <mat-chip
                            *ngFor="let tag of Experience.tags"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="remove(tag)">
                            {{tag}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input
                            placeholder="Tags"
                            #tagInput
                            [formControl]="tagCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="tagList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                            {{tag}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_experience()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete-mod-exp" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Experience</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar la experiencia {{Experience.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_experience()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>


<!-- ############################ WORKPLACE MODALS ################################# -->

  <div class="modal fade" id="workplace-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Workplace</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-3">Name</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Workplace.name" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-6">shortname</div>
              <div class="col-sm-6">
                  <mat-form-field color="accent" appearance="outline">
                      <input required type="text" matInput name="shortname" [(ngModel)]="Workplace.shortname" >
                  </mat-form-field>
              </div>
          </div>
            <div class="row">
                <div class="col-sm-3">Description</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <textarea name="" [(ngModel)]="Workplace.description" matInput id="" cols="30" rows="10"></textarea>
                    </mat-form-field>
                </div>
            </div>
            
            
            <div class="row">
                <div class="col-sm-3">Date ini</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input [(ngModel)]="Workplace.date_ini" matInput placeholder="Date ini" [matDatepicker]="picker3">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-3">Date end</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input [disabled]="Workplace.still_active" [(ngModel)]="Workplace.date_end" matInput placeholder="Date end" [matDatepicker]="picker4">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field> <br>
                    <mat-checkbox appearance="outline" [(ngModel)]="Workplace.still_active">Still active?</mat-checkbox>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_workplace()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete-mod-wrk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Workplace</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar el workplace {{Workplace.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_workplace()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>