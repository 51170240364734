<br><br><br>
<div class="row justify-content-sm-center">
    <div class="col-md-12">
        <div class="row">
            <!-- Projects code -->
            <div class="col-sm-6">
                <h2>Projects</h2>
                <br><br>

                <div class="row">
                    <div class="col-sm-12 card pro-card" *ngFor="let pro of Projects" [ngStyle]="{'border': Project.id == pro.id?'solid 3px blue':''}" (click)="select_project(pro)">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h3>{{pro.name}}</h3>
                                        <p class="card-text">{{pro.subtitle}}</p>
                                    </div>
                                    <div class="col-sm-3">
                                        <a (click)="set_preupdate_project(pro)" data-toggle="modal" data-target="#project-mod" class="btn btn-primary">edit</a>
                                        <br><a (click)="set_deletable_project(pro)" data-toggle="modal" data-target="#delete-mod-pro" class="btn btn-danger">delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-sm-12">
                        <a class="btn btn-success" href="" data-toggle="modal" (click)="setNewProject()" data-target="#project-mod">Add project</a>
                    </div>
                </div>
            </div>

            <!-- Images code -->
            <div class="col-sm-6">
                <h2>Images</h2>
                <br><br>
                <div class="row">
                    <div class="col-sm-12 card img-card" *ngFor="let img of Project.images" [ngStyle]="{'border': Image.id == img.id?'solid 3px blue':''}">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h3>{{img.name}}</h3>
                                        <img style="height:75px;" [src]="img.image" alt="">
                                    </div>
                                    <div class="col-sm-3">
                                        <a (click)="set_preupdate_image(img)" data-toggle="modal" data-target="#image-mod" class="btn btn-primary">edit</a>
                                        <br><a (click)="set_deletable_image(img)" data-toggle="modal" data-target="#delete-mod-img" class="btn btn-danger">delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <br><br>
                <div class="row" [hidden]="!Project.id">
                    <div class="col-sm-12">
                        <a class="btn btn-success" href="" data-toggle="modal" (click)="setNewImage()" data-target="#image-mod">Add image</a>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        
    </div>
</div>
<!-- ############################ IMAGE MODALS ################################# -->

<div class="modal fade" id="image-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Image</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-3">Name</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Image.name" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">Order</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="order" [(ngModel)]="Image.order" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    Image
                </div>
                <div class="col-sm-9">
                    <a class="btn btn-outline-info" onclick="document.getElementById('image_file').click()">
                        Choose File
                    </a>
                    <input type="file" #file (change)="previewFile(file.files)" id="image_file" accept="image/png, image/jpeg" style="display:none"/>
                    <img [hidden]="!project_image_url" [src]="project_image_url" width="350px">
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_image()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete-mod-img" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Image</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar la imagen {{Image.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_image()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>


<!-- ############################ PROJECT MODALS ################################# -->

  <div class="modal fade" id="project-mod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:1200px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Project</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-3">Name</div>
                <div class="col-sm-9">
                    <mat-form-field color="accent" appearance="outline">
                        <input required type="text" matInput name="name" [(ngModel)]="Project.name" >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-3">Subtitle</div>
              <div class="col-sm-9">
                  <mat-form-field color="accent" appearance="outline">
                      <input required type="text" matInput name="subtitle" [(ngModel)]="Project.subtitle" >
                  </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">shortname</div>
              <div class="col-sm-9">
                  <mat-form-field color="accent" appearance="outline">
                      <input required type="text" matInput name="shortname" [(ngModel)]="Project.shortname" >
                  </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-3">Body</div>
                <div class="col-sm-9" style="height:600px;">
                    <ace [mode]="'html'" [theme]="'chrome'" [(value)]="Project.body"></ace>
                    <!--<mat-form-field color="accent" appearance="outline">
                        <textarea name="" [(ngModel)]="Project.body" matInput id="" cols="120" rows="40"></textarea>
                    </mat-form-field>-->
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="create_update_project()" data-dismiss="modal" aria-label="Close">Save Changes / Create</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete-mod-pro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="min-width:800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Project</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            ¿Estás seguro que quieres borrar el project {{Project.name}}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">NO</button>
          <button type="button" class="btn btn-dark" (click)="delete_project()" data-dismiss="modal" aria-label="Close">SÍ</button>
        </div>
      </div>
    </div>
  </div>