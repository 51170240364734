import { Component, OnInit, ElementRef, ViewChild, Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Discipline} from "../discipline";
import {Workplace} from "../workplace";
import {Project} from "../project";
import {map, startWith} from 'rxjs/operators';
import {MainService} from '../services/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  exp_filters:string[] = [];
  workplaces:Workplace[];
  disciplines:Discipline[];
  projects:Project[];
  toggleImages:boolean=true;
  //visibleProject:Project={name:""};
  //showingProject:boolean=false;
  constructor(private router:Router, private _mainService:MainService,@Inject(DOCUMENT) private document: Document, private renderer:Renderer2,private sanitizer:DomSanitizer) {
    //this._mainService.getAll().subscribe();
  }

  @ViewChild('project-viewer') projectViewer: ElementRef<any>;

  ngOnInit(): void {
    this._mainService.getAll().subscribe(data=>{
      this._mainService.data.next(data);
      this.workplaces=data.workplaces;
      this.disciplines=data.disciplines;
      this.projects=data.projects;
    });
    this.renderer.removeClass(this.document.body, 'mat-typography');
    //this.document.body.classList.remove('mat-typography');
  }
  urlActive(txt){
    return this.router.url.indexOf(txt)>-1;
  }
  /*showProject(proj){
    this.visibleProject = proj;
    this.showingProject = true;
  }
  closeProject(){
    this.showingProject = false;
  }*/


}
