import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'expfilter',
    pure: false
})
export class ExpPipe implements PipeTransform {
    transform(items: any[], filter: any[]): any {
        //console.log(items);
        if (!items || !filter || filter.length==0) {
            return items;
        }
        var res = items.filter(f => filter.every(tag=>f.tags.includes(tag)));
        //console.log(res);
        //var filtered =  items.filter(e => {return items.indexOf(e) < 0;},filter);
        return res;
    }
}